
import "./NavBar.css"

import { Link, useLocation } from 'react-router-dom'

const nav_map = [
    { name: "Home", url: "/" },

    { name: "Intro CS", url: "/introcs"},

    /*{ name: "For Observers", url: "/observers" },
    { name: "Teaching Philosophy", url: "/philosophy" },
    { name: "Portfolio", url: "/portfolio/uteach" },
    { name: "Resume", url: "/resume"},*/
    
    /*{ name: "Programming", url: "/programming" },*/
    /*{ name: "About", url: "/about" },*/
]

export default function NavBar() {

    let locationURL = useLocation().pathname;

    const links = nav_map.map(item =>
        <li className="nav-item mx-2">
            <Link
                className={item.url == locationURL
                    ? "my-nb nav-link active"
                    : "my-nb nav-link"
                }
                to={item.url}>
                {item.name}
            </Link>
        </li>
    );

    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid px-2 px-sm-4">
                
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav nav my-4 mx-2 justify-content-center">
                        {links}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

import SnippetPy from "../components/SnippetPy";
import Unipage from "../templates/Unipage";

export default function Functions() {

    const starter =
`def main():
    pass

if __name__ == "main":
    main()
`;

    return (<Unipage
        content={
            <>
                <h2>Display Song Lyrics Using Python</h2>

                <h3>Starter Code</h3>
                <p>Type the following code into your IDE. We will build our program starting from this code.</p>
                <SnippetPy code={starter} />
                

            </>
        }
    />);

}
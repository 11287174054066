
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import Programming from "./pages/Programming";
import Splash from "./pages/Splash";
import Teaching from "./pages/Teaching";
import About from "./pages/About";
import Education from "./pages/Education";
import Observers from "./pages/Observers";
import Philosophy from "./pages/Philosophy";
import Resume from "./pages/Resume";
import Buzzer from "./arduino/Buzzer";
import Speaker from "./arduino/Speaker";
import Stoplight from "./arduino/Stoplight";
import Ultrasonic from "./arduino/Ultrasonic";
import Servo from "./arduino/Servo";
import GrandDesign from "./arduino/GrandDesign";

import HelloWorld from "./introcs/HelloWorld";

import Functions from "./python/Functions";

import SplashNavarro from "./pages/SplashNavarro";


const react_router = createBrowserRouter([
    { path: "/", element: <SplashNavarro /> },
    //{ path: "/observers", element: <Observers /> },
    //{ path: "/philosophy", element: <Philosophy/> },
    //{ path: "/education", element: <Education/>},
    //{ path: "/portfolio/uteach", element: <Teaching/> },
    //{ path: "/portfolio/uteach/:section", element: <Teaching/> },

    //{ path: "/about", element: <About/> },

    //{ path: "/resume", element: <Resume /> },

    /*{ path: "/arduino", element: <GrandDesign/> },
    { path: "/arduino/buzzer", element: <Buzzer /> },
    { path: "/arduino/speaker", element: <Speaker /> },
    { path: "/arduino/stoplight", element: <Stoplight /> },
    { path: "/arduino/ultrasonic", element: <Ultrasonic /> },
    { path: "/arduino/servo", element: <Servo /> },*/

    { path: "/python", element: <Functions/>},

]);

export default function RouteSwitch() {

    return (
        <RouterProvider router={react_router}/>
    );
}
